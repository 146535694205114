'use client';
import ColorRevealHero from '@/components/colorRevealHero';
import Footer from '@/components/footer';
import Navbar from '@/components/navbar';

const LandingLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col min-h-screen relative overflow-x-hidden">
      <main className="flex-grow">
        <header>
          <Navbar />
        </header>
        <ColorRevealHero
          imageSrc="https://utfs.io/f/wXFHUNfTHmLja048nYpM16gwcQVyxhKSB0vU2qOoIJE4zYkt"
          alt={'Hero Image'}
          className="w-full h-[400px] sm:h-[500px] md:h-[600px]"
        />
        <div className="min-h-screen">{children}</div>
      </main>
      <Footer />
    </div>
  );
};

export default LandingLayout;