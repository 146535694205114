import Image from 'next/image';
import React, { useState, useEffect, useRef } from 'react';

interface ColorRevealHeroProps {
    imageSrc: string;
    alt: string;
    revealSize?: number;
    blurSize?: number;
    className?: string;
}

interface MousePosition {
    x: number;
    y: number;
}

const ColorRevealHero: React.FC<ColorRevealHeroProps> = ({
    imageSrc,
    alt,
    revealSize = 100,
    blurSize = 25,
    className = ''
}) => {
    const [mousePosition, setMousePosition] = useState<MousePosition>({ x: 0, y: 0 });
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleMouseMove = (event: MouseEvent) => {
            if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                setMousePosition({
                    x: event.clientX - rect.left,
                    y: event.clientY - rect.top,
                });
            }
        };

        const handleMouseEnter = () => setIsHovering(true);
        const handleMouseLeave = () => setIsHovering(false);

        const currentRef = containerRef.current;
        if (currentRef) {
            currentRef.addEventListener('mousemove', handleMouseMove);
            currentRef.addEventListener('mouseenter', handleMouseEnter);
            currentRef.addEventListener('mouseleave', handleMouseLeave);
        }

        return () => {
            if (currentRef) {
                currentRef.removeEventListener('mousemove', handleMouseMove);
                currentRef.removeEventListener('mouseenter', handleMouseEnter);
                currentRef.removeEventListener('mouseleave', handleMouseLeave);
            }
        };
    }, []);

    const maskImage = `radial-gradient(circle ${revealSize}px at ${mousePosition.x}px ${mousePosition.y}px, black 0%, black ${revealSize - blurSize}px, transparent ${revealSize}px)`;

    const revealStyle: React.CSSProperties = {
        opacity: isHovering ? 1 : 0,
        maskImage: maskImage,
        WebkitMaskImage: maskImage,
    };

    return (
        <div ref={containerRef} className={`relative overflow-hidden ${className}`}>
            <Image
                src={'https://utfs.io/f/wXFHUNfTHmLjrYZK3p0s1HUpv3bEm5dMQGaiR2NoyBPW9TDC'}
                alt={alt}
                fill
                priority
                className="absolute inset-0 w-full object-cover min-h-[600px]"
            />
            <div
                className="absolute inset-0 w-full h-full transition-opacity duration-300 hidden lg:block"
                style={revealStyle}
            >
                <Image
                    src={imageSrc}
                    alt={`Color reveal of ${alt}`}
                    fill
                    className="w-full object-cover min-h-[600px]"
                />
            </div>
            <div className='absolute bottom-0 bg-gradient-to-t from-background to-transparent h-20 md:h-40 w-full'></div>
        </div>
    );
};

export default ColorRevealHero;